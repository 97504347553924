.news-list {
    overflow: hidden;
  }
  
  .news-item {
    animation: scrollNews 37s linear infinite;
    animation-direction: reverse;
    color:white
  }
  
  @keyframes scrollNews {
    0% {
      transform: translateX(-100%);
    }
    100% {
      transform: translateX(100%);
    }
  }
  /* .news-item-New {
    animation: scrollNewsNew 5s linear infinite;
    color:white
  }
  
  @keyframes scrollNewsNew {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(100%);
    }
  } */