@media screen and (max-width: 400px) {
  #features {
    padding: 20px;
    width: 111%;
  }
  #about,
  #services,
  #testimonials,
  #team,
  #contact,
  #footer {
    width: 111%;
  }

  #portfolio {
    width: 110%;
  }
}

@keyframes shrink-grow {
  0% {
    transform: scale(1); /* Original size */
  }
  50% {
    transform: scale(0.8); /* Shrink to 80% */
  }
  100% {
    transform: scale(1); /* Back to original size */
  }
}

.shrink-icon {
  /* color: #25D366; */
  /* font-size: 24px; */
  animation: shrink-grow 2s ease-in-out infinite; /* Continuous shrink-grow animation */
}
